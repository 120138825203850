import React from 'react'
import { Top, Footer, Loading, Meta, Advice } from '@src/share-component'
import AboutImage from '@src/asset/about_4.jpg'
import '../templates/home/home.css'
import Construct from '@src/asset/construct1.jpg'
import { useIntl } from "gatsby-plugin-react-intl"
import '@src/styles/common.css'
import Typography from '@material-ui/core/Typography';

const About = () => {

  const intl = useIntl()

  return <React.Fragment>
    <Meta
      title = { intl.formatMessage({ id : "about-title" }) }
      keywords = { intl.formatMessage({ id : "about-keywords" }) }
      slug = "/about/"
    />
    <Loading/>
    <div class = 'page'>
      <Top useColorBar slogan = { intl.formatMessage({ id : "about-title" }) }/>
      <div id = 'about' class = 'index-block'>
      <img id = 'index-about-bg' src = {AboutImage} class = "index-block-bg" />
      <div class = 'index-block2'>
      <Typography variant = "h4" component = "h2" classes = {{ root : "index-block2-title" }}>
        { intl.formatMessage({ id : "about-title"}) }
      </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : "index-block2-content" }}>
        { intl.formatMessage({ id : "index-about-content"}) }
      </Typography>
      </div>
    </div>

      <div id = 'index-why' class = 'index-block'>
        <img id = 'index-why-bg' src = {Construct} class = "index-block-bg" />
        <div class = 'index-block2-right'>
        <Typography variant = "h4" component = "h2" classes = {{ root : "index-block2-title" }}>
        { intl.formatMessage({ id : "index-why-title"}) }
        </Typography>
        <Typography variant = "body1" component = "p" classes = {{ root : "index-block2-content" }}>
        { intl.formatMessage({ id : "index-why-content"}) }
        </Typography>
        </div>
      </div>
      <Advice />
      <Footer/>
    </div>
  </React.Fragment>

}



export default About
